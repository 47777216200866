:root {
  /* Full Calendar */
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #1876d2;
  --fc-button-border-color: #1876d2;
  --fc-button-hover-bg-color: rgb(17, 82, 147);
  --fc-button-hover-border-color: rgb(17, 82, 147);
  --fc-button-active-bg-color: rgb(17, 82, 147);
  --fc-button-active-border-color: rgb(17, 82, 147);
}

.fc-toolbar .fc-button-primary[class^="fc-filterButton"] {
  background-color: 1876d2;
  color: #fff;
}

/* Active blue buttons */
.fc-toolbar .fc-button-primary[class^="fc-filterButton"].inactive-button {
  background-color: gray; 
  color: white;
}

ul{
  list-style-type: none;
}

.bottom-navigation-paper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0;
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

.navigation-icon {
 width: 35px !important;
 height: 35px !important;
}

.center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-container {
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.vertical-centered-container {
  display: flex !important;
  align-items: center;
}

.center-both-container{
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.contact-us-button {
  margin-top: 60px;
  position: fixed;
  bottom: -10px;
  right: 10px;
}

.copyright-button {
  position: fixed;
  bottom: 0px;
  left: 10px;
  text-decoration: none;
}

.copyright-button:hover {
  text-decoration: underline;
}

.copyright-button:visited {
  color: #282c34;
}

.transparent-background {
  background-color: transparent !important;
}

.progressBarContainer {
  position: relative;
  height: 24px;
  width: 180px;
  margin: auto;
  border-radius: 15px;
  border: 2px solid transparent;
  background: #dfdfe7;
  overflow: hidden;
}

.progressBarFillerBackground {
  position: relative;
  height: inherit;
}

.progressBarFiller {
  position: absolute;
  height: inherit;
  overflow: hidden;
  background: inherit;
}

.progressBarTickers {
  display: "flex";
  justify-content: "space-between";
}

.FacilityListCard{
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.maintenance-form-img {
  object-fit: cover;
  width: inherit;
}

.membrane-button{
  max-width: fit-content;
}

.blue-menu-item{
  flex-direction: column;
  width: 70px;
}
.blue-menu-item ::selection {
  color: blue
}

.recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
  cursor: pointer;
  text-align: center;
}
.recharts-legend-wrapper .recharts-default-legend .recharts-legend-item:hover {
  opacity: 0.5;
}

/* Styling for react-grid-layout package */
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
/* .react-grid-item.cssTransforms {
  transition-property: transform;
} */
.react-grid-item.resizing {
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
}

.react-grid-item.react-grid-placeholder {
  background: #000;
  opacity: 0.1;
  transition-duration: 100ms; 
  z-index: 2;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* .react-grid-item:hover > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
} */

/* Makes the google places dropdown show up on modals (material-ui modal z-index: 1300)*/
.pac-container {
  z-index: 1301 !important;
}

/*Disables the arrows that show up on number type text fields*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* FullCalendar */
@media (max-width: 600px) {
  .fc-header-toolbar {
      font-size: 12px; /* or whatever size you find appropriate */
      flex-direction: column;
  }
  .fc-toolbar-chunk:not(:last-child) {
      margin-bottom: 5px;
  }
}

/* Fix for the scrollbar showing up wrong on mui data grid tables */
.css-1rtad1 {
  display: block !important;
}